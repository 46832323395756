@use "../../styles/mixins" as *;
@mixin header {
    width: 100%;
    display: grid;
    align-items: center;
    grid-template-columns: 1fr min-content;
    column-gap: var(--g-size-xxs);
    font-family: var(--g-font-family-primary);
    font-weight: var(--g-font-weight-regular);
    color: var(--s-color-text-primary);
    font-size: var(--g-font-size-400);
    line-height: var(--g-line-height-400);
}

@include layer(ui) {
    button.header {
        all: unset;
        @include header;
        cursor: pointer;

        &--no-icon {
            column-gap: 0;
        }
    }

    .header {
        @include header;
    }

    .trigger {
        cursor: pointer;

        &:focus-visible {
            outline-width: var(--g-border-width-sm);
            outline-color: var(--s-color-brand-primary-standard);
            outline-offset: var(--g-spacing-xxs);
            outline-style: solid;
            border-radius: var(--c-collapsible-trigger-border-radius-focus);
        }

        &[aria-disabled="true"] {
            cursor: not-allowed;
            color: var(--s-color-disabled-primary);

            .icon svg {
                color: var(--s-color-disabled-primary);
            }
        }

        &-icon-only {
            all: unset;
            padding: var(--g-spacing-sm);
            font-family: var(--g-font-family-primary);
            font-weight: var(--g-font-weight-regular);
            color: var(--s-color-text-primary);
            font-size: var(--g-font-size-400);
            line-height: var(--g-line-height-400);
            min-height: 24px;
            display: flex;
            cursor: pointer;
        }
    }

    .title {
        min-width: 0;
    }

    .top-border {
        border-top: var(--g-border-width-xs) solid
            var(--s-color-separator-standard);
    }

    .bottom-border {
        &:last-child {
            border-bottom: var(--g-border-width-xs) solid
                var(--s-color-separator-standard);
        }
    }

    .padding {
        padding: var(--g-spacing-md) var(--g-spacing-sm);

        @include for-tablet-up {
            padding: var(--g-spacing-lg) var(--g-spacing-md);
        }
    }

    .collapsible {
        font-family: var(--g-font-family-primary);
        color: var(--s-color-text-primary);

        .icon {
            transition: transform var(--g-duration-xshort) ease-in-out;
        }

        @media (prefers-reduced-motion) {
            .icon {
                transition: none;
            }
        }
    }

    .expanded {
        .icon {
            transform: rotate(180deg);
        }
    }

    // This is needed to have collapsibles in collapsibles which don't open each other's svgs.
    // It works because the `collapsed` class is CLOSER than the `expanded` class.

    .collapsed {
        .icon {
            transform: rotate(0);
        }
    }

    .content {
        display: flex;
        flex-direction: column;
        font-weight: var(--g-font-weight-light);
    }

    .content-padding {
        padding-top: var(--g-spacing-md);

        @include for-tablet-up {
            padding-top: var(--g-spacing-lg);
        }
    }
}
